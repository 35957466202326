import { EventEmitter, Injectable } from '@angular/core';
import { LoginResult, User } from '@app/interfaces/models';
import { version } from '@app/version';
import { BehaviorSubject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private onlyMyOrderPreference:boolean = false;
  private companyTourTriggered:boolean = false;
  private user:User = null;
  private userPhoto:Array<String> = [];
  private accessToken:String = null;
  private authenticated:boolean = false;
  private permissions:Array<String> = [];
  private redirectUrl:String = '';
  private cache_key:string = '_v5';

  private impersonateActive: boolean = false;
  private impersonatedUser:User = null;
  private impersonatedUserPhotos: Array<String> = [];
  private impersonatedAccessToken:String = null;
  private impersonatedPermissions: Array<String> = [];

  public userChangedEvent: EventEmitter<User> = new EventEmitter<User>();
  public onlyMyOrderPreferenceChangeEvent: EventEmitter<boolean> = new EventEmitter<boolean>();


  private adminView:BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public adminView$ = this.adminView.asObservable();

  constructor (
  ) {
    this.getVersion();
    this.getAccessTokenPromise();
    this.impersonateActive = (JSON.parse(localStorage.getItem('app_token_impersonated'+this.cache_key)) !== null);
    this.adminMode();
  }

  adminMode(){
    this.adminView.next((localStorage.getItem('setAdminMode') === 'true'));
  }

  setAdminMode(input:boolean) {
    localStorage.setItem('setAdminMode', input.toString());
    this.adminView.next(input);
  }

  private getVersion(){
    this.cache_key = '_v'+version.toString();
    console.log('version', version);
  }

  public isImpersonating() {
    return this.impersonateActive;
  }
  public setImpersonation(user: LoginResult){
    this.adminView.next(false);
    this.setUser(user.myuser, true);
    if(user.myuser.photo_sm) {
      this.setUserPhoto(user.myuser.photo_sm, 0, true);
    }
    if(user.myuser.photo_md) {
      this.setUserPhoto(user.myuser.photo_md, 1, true);
    }
    if(user.myuser.photo_lg) {
      this.setUserPhoto(user.myuser.photo_lg, 2, true);
    }
    this.setAccessToken(user.token, true);
    this.setPermissions(user.permissions, true);
    
    this.impersonateActive = true;

    this.setOnlyMyOrderPreference(false, false);
    this.userChangedEvent.emit(user.myuser);
  }
  public clearImpersonation() {
    this.impersonateActive = false;

    this.clearUserStorage(true);
    this.impersonatedUser = null;

    this.clearUserPhotoStorage(0, true);
    this.clearUserPhotoStorage(1, true);
    this.clearUserPhotoStorage(2, true);
    this.impersonatedUserPhotos = [];

    this.clearAccessTokenStorage(true);
    this.impersonatedAccessToken = null;

    this.clearPermissionsStorage(true);
    this.impersonatedPermissions = []

    this.setOnlyMyOrderPreference(false, false);
    this.userChangedEvent.emit(this.user);
  }

  public setOnlyMyOrderPreference(value:boolean, emit_event:boolean = true){
    localStorage.setItem('onlyMyOrderPreference', value.toString());
    this.onlyMyOrderPreference = value;
    if(emit_event){
      this.onlyMyOrderPreferenceChangeEvent.emit(value);
    }
  }
  public getOnlyMyOrderPreference(): boolean{
    this.onlyMyOrderPreference = (localStorage.getItem('onlyMyOrderPreference') === 'true');
    return this.onlyMyOrderPreference;
  }

  public setCompanyTour(value:boolean){
    localStorage.setItem('companyTourTriggered', value.toString());
    this.companyTourTriggered = value;
  }
  public getCompanyTour(): boolean{
    this.companyTourTriggered = (localStorage.getItem('companyTourTriggered') === 'true');
    return this.companyTourTriggered;
  }

  public setUser(value, impersonate = this.impersonateActive){
    if(impersonate) {
      localStorage.setItem('my_user_impersonated'+this.cache_key, JSON.stringify(value));
      this.impersonatedUser = value;
    } else {
      localStorage.setItem('my_user'+this.cache_key, JSON.stringify(value));
      this.user = value;
    }
  }
  public clearUserStorage(impersonate = false) {
    localStorage.removeItem('my_user' + (impersonate ? '_impersonated' : '') + this.cache_key);
  }
  public getUser(){
    if(this.impersonateActive) {
      if (JSON.parse(localStorage.getItem('my_user_impersonated'+this.cache_key)) !== null) {
        this.impersonatedUser = JSON.parse(localStorage.getItem('my_user_impersonated'+this.cache_key));
      }
      return this.impersonatedUser;
    } else {
      if (JSON.parse(localStorage.getItem('my_user'+this.cache_key)) !== null) {
        this.user = JSON.parse(localStorage.getItem('my_user'+this.cache_key));
      }
      return this.user;
    }
  }

  public isBrokerageCompany(){
    return (this.getUser() && this.getUser().company && this.getUser().company.company_type && this.getUser().company.company_type.name.toLowerCase() === 'brokerage');
  }
  public isInstallerCompany(){
    return (this.getUser() && this.getUser().company && this.getUser().company.company_type && this.getUser().company.company_type.name.toLowerCase() === 'installer');
  }

  public hasRole(role:string){
    if(this.getUser() && this.getUser().roles){
      return this.getUser().roles.filter((item) => item.name === role).length > 0;
    }
    return false;
  }

  public setUserInviteText(value){
    let user = this.getUser();
    user.invite_text = value;
    this.setUser(user);
  }

  public setUserPhoto(value, size, impersonate = this.impersonateActive){
    if(impersonate) {
      localStorage.setItem('my_user_photo_impersonated_'+size+this.cache_key, JSON.stringify(value));
      this.impersonatedUserPhotos[size] = value;
    } else {
      localStorage.setItem('my_user_photo_'+size+this.cache_key, JSON.stringify(value));
      this.userPhoto[size] = value;
    }
  }
  public clearUserPhotoStorage(size: number, impersonate = false) {
    localStorage.removeItem('my_user' + (impersonate ? '_impersonated_' : '_') + size + this.cache_key);
  }
  public getUserPhoto(size):String{
    if(this.impersonateActive) {
      if (JSON.parse(localStorage.getItem('my_user_photo_impersonated_'+size+this.cache_key)) !== null) {
        this.impersonatedUserPhotos[size] = JSON.parse(localStorage.getItem('my_user_photo_impersonated_'+size+this.cache_key));
      }
      return this.impersonatedUserPhotos[size] ?? null;
    } else {
      if (JSON.parse(localStorage.getItem('my_user_photo_'+size+this.cache_key)) !== null) {
        this.userPhoto[size] = JSON.parse(localStorage.getItem('my_user_photo_'+size+this.cache_key));
      }
      return this.userPhoto[size] ?? null;
    }
  }

  public setAccessToken(value:String, impersonate = this.impersonateActive){
    if(impersonate) {
      localStorage.setItem('app_token_impersonated'+this.cache_key, JSON.stringify(value));
      this.impersonatedAccessToken = value;
    } else {
      localStorage.setItem('app_token'+this.cache_key, JSON.stringify(value));
      this.accessToken = value;
    }
  }
  public clearAccessTokenStorage(impersonate = false) {
    localStorage.removeItem('app_token' + (impersonate ? '_impersonated' : '') + this.cache_key);
  }
  public getAccessToken(){
    if(this.impersonateActive) {
      if (JSON.parse(localStorage.getItem('app_token_impersonated'+this.cache_key)) !== null) {
        this.impersonatedAccessToken = JSON.parse(localStorage.getItem('app_token_impersonated'+this.cache_key));
      }
      return this.impersonatedAccessToken;
    } else {
      if (JSON.parse(localStorage.getItem('app_token'+this.cache_key)) !== null) {
        this.accessToken = JSON.parse(localStorage.getItem('app_token'+this.cache_key));
      }
      return this.accessToken;
    }
  }
  public getAccessTokenPromise(){
    return new Promise((resolve) => {
      this.getPermissions();
      resolve(this.getAccessToken());
    });
  }

  public setPermissions(values:Array<String>, impersonate = this.impersonateActive){
    if(impersonate) {
      localStorage.setItem('permissions_impersonated'+this.cache_key, JSON.stringify(values));
      this.impersonatedPermissions = values;
    } else {
      localStorage.setItem('permissions'+this.cache_key, JSON.stringify(values));
      this.permissions = values;
    }
  }
  public clearPermissionsStorage(impersonate = false) {
    localStorage.removeItem('permissions' + (impersonate ? '_impersonated' : '') + this.cache_key);
  }
  public getPermissions(){
    if(this.impersonateActive) {
      if (JSON.parse(localStorage.getItem('permissions_impersonated'+this.cache_key)) !== null) {
        this.impersonatedPermissions = JSON.parse(localStorage.getItem('permissions_impersonated'+this.cache_key));
      }
      return this.impersonatedPermissions;
    } else {
      if (JSON.parse(localStorage.getItem('permissions'+this.cache_key)) !== null) {
        this.permissions = JSON.parse(localStorage.getItem('permissions'+this.cache_key));
      }
      return this.permissions;
    }
  }

  public checkPermission(p:string = null){
    return this.getPermissions().indexOf(p) !== -1;
  }
  public checkPermissions(check: string[]) {
    return check.some(p => this.checkPermission(p));
  }

  public checkPermissionForOffice(check: string, officeId: number): boolean {
    return this.getUser().offices.map(o => o.id).some(x => x == officeId) && this.checkPermission('office_' + check);
  }
  public checkPermissionForCompany(check: string, companyId: number): boolean {
    return (this.getUser().company_id == companyId) && this.checkPermission('company_' + check);
  }

  public setRedirectUrl(value:String){
    this.redirectUrl = value;
  }
  public getRedirectUrl(){
    return this.redirectUrl;
  }

  public isAuthenticated(): boolean {
    if(this.accessToken === null){
      this.authenticated = false;
    } else {
      this.authenticated = true;
    }
    return this.authenticated;
  }

}
