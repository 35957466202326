import { Injectable } from '@angular/core';
import { Geolocation, Position } from '@capacitor/geolocation';

@Injectable({
  providedIn: 'root'
})
export class LocationService {

  public position = null;

  constructor() {
    console.log('LocationService');
  }

  async getCurrentPosition(): Promise<Position> {
    return new Promise((resolve, reject)=> {
      Geolocation.getCurrentPosition({timeout: 20000, enableHighAccuracy: false}).then((position) => {
        this.position = position;
        console.log(position);
        resolve(position);
      }).catch((err) => {
        console.error(err);
        reject(err);
      });
    });
  }

}
